.doggo-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .doggo-popup-content {
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: black;
  }
  
  .doggo-popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .doggo-popup-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }