.booster-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .booster-item {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 300px;
  }
  .booster-data{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;;
    width: 100%;
  }
  .booster-coins{
    color: yellow;
    margin-right: 10px;
  }
  .booster-level{
    color: yellow;
  }

  .time-indicator {
    position: absolute;
    top: -4px;
    right: 4px;
    background-color: #ff9800; /* Orange background, you can change this */
    color: white;
    padding: 2px 6px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .booster-icon{
    margin-right: 10px;
  }