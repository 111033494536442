.card {
    width: 140px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: left;
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card.locked {
    background-color: #6a462e;
    cursor: not-allowed;
  }
  
  .card h3 {
    margin: 110px 0 10px 0;  /* Adjust margin to account for the image */
    font-size: 16px;
    font-weight: 600;
    color: #0f0f0f;
    text-align: left;
  }
  
  .card .profit,
  .card .pay {
    margin: 5px 0;
    font-size: 14px;
    color: #B6784F;
  }
  
  .card.locked .profit,
  .card.locked .pay {
    margin: 5px 0;
    font-size: 14px;
    color: #2a2a2a;
  }
  .card.locked::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
  }
  
  .card.locked .lock-icon {
    position: absolute;
    top: 12%;
    left: 22%;
    transform: translate(-25%, -25%);
    font-size: 58px;
    color: #999;
  }
  
  .card.locked .unlock-requirement {
    margin-top: 10px;
    color: #999;
    font-size: 14px;
  }
  
  .card .icon-image {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10px;
    left: 10px;
    user-select: none; /* Standard property */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  pointer-events: none; /* Prevents click events */
  }
  .card.locked .icon-image{
    opacity: 0.4;
  }
  .card .level {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: #F6C427;
    color: #000;
    padding: 2px 6px;
    border-radius: 5px;
  }
  
  .card .pay {
    margin-top: 10px;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    background-color: #F6C427;
    color: #fff;
    border-radius: 5px;
    text-align: center;
  }
  