.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  color: #333;
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
  color: #666;
}

.rewards-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin: 20px 0;
  width: 100%;
}

.reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  border-radius: 10px;
  background-color: #f0f0f0;
  width: 100%;
  text-align: center;
}

.reward.active {
  background-color: #ffdd57; /* Gold color for active rewards */
}

.reward.inactive {
  background-color: #e0e0e0; /* Gray color for inactive rewards */
}

.day-text {
  font-weight: bold;
  font-size: 12px;
  color: #000;
  margin-bottom: 5px;
}

.coin-image {
  width: 30px;
  height: 30px;
}

.amount-text {
  font-size: 14px;
  margin-top: 5px;
  color: #000;
}

.button {
  margin-top: 20px;
  background-color: #FFA726; /* Red color for the button */
  padding: 15px 30px;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.button:disabled {
  background-color: #ccc;
}

@media (max-width: 768px) {
  .rewards-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
  }

  .button {
    width: 100%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
  }

  .rewards-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
  }

  .button {
    font-size: 14px;
    padding: 10px;
  }
}

.reward.active .day-text,
.reward.active .amount-text {
  color: #000;
}

.reward.inactive .day-text,
.reward.inactive .amount-text {
  color: #999;
}


.image-container {
  margin-top: 20px;
}

.cute-character {
  width: 161px;
  height: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
