.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-container {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    max-width: 350px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .popup-image {
    width: 190px;
    height: 100px;
    margin-bottom: 15px;
  }
  
  .popup-message {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    line-height: 1.4;
  }
  
  .popup-amount {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  
  .collect-button {
    background-color: #FFD700;
    color: #000;
    border: none;
    border-radius: 25px;
    padding: 12px 30px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .collect-button:hover {
    background-color: #FFC700;
  }