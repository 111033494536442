.tasks-container {
    min-height: 100vh;
    padding: 20px;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .title {
    flex-grow: 1;
    text-align: center;
    font-size: 24px;
    color: #745E50;
    margin: 0;
  }
  
  .tasks-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-container {
    margin-bottom: 20px;
  }
  
  .cute-characters {
    width: 133px;
    height: auto;
  }
  
  .instruction-text {
    text-align: center;
    color: #745E50;
    margin-bottom: 20px;
  }
  
  .tasks-list {
    width: 100%;
    border-radius: 15px;
    padding: 20px;
  }
  
  .tasks-list h2 {
    color: #745E50;
    margin-bottom: 15px;
  }
  
  .task-item {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .task-info {
    display: flex;
    align-items: center;
  }
  
  .task-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .task-title {
    color: #745E50;
    font-weight: bold;
  }
  .task-content {
    display: flex;
    flex-direction: column;
  }
  
  .task-reward {
    display: flex;
    align-items: center;
  }
  
  .task-coins {
    color: #FFA726;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .claim-button {
    background-color: #FFA726;
    color: white;
    border: none;
    border-radius: 15px;
    padding: 5px 15px;
    font-weight: bold;
    cursor: pointer;
  }