.user-friend-container {
  background-color: #e8c4a2;
  font-family: Arial, sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #e8c4a2;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  color: #745E50;
}

.user-friend-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.image-container {
  margin-bottom: 20px;
}

.friend-cute-character {
  width: 103px;
  height: 120px;
}

.reward-text, .bonus-text {
  text-align: center;
  margin: 5px 0;
  color: #745E50;
}

.bonus-text {
  font-size: 0.9em;
  color: #A67C52;
}

.friends-container {
  width: 100%;
  margin-top: 20px;
}

.friends-container h2 {
  font-size: 16px;
  color: #745E50;
  margin-bottom: 10px;
}

.friend-list {
  list-style: none;
  padding: 0;
}

.friend-item {
  display: flex;
  align-items: center;
  background-color: #FDE7C7;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.friend-avatar {
  width: 30px;
  height: 30px;
  background-color: #D9D9D9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: #745E50;
}

.friend-name {
  flex-grow: 1;
  color: #745E50;
}

.friend-tokens {
  color: #FFA726;
  font-weight: bold;
}

.invite-button {
  background-color: #FFA726;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

.invite-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
  width:100%;
}

.invite-button {
  background-color: #90542f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin:0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  
}

.copy-button {
  background-color: #b6784f;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.copy-button svg {
  width: 24px;
  height: 24px;
  color: white;
}

.invite-button:hover, .copy-button:hover {
  opacity: 0.9;
}

.invite-button:active, .copy-button:active {
  opacity: 0.8;
}