/* Container for the navbar */
:root {
  --bg-clr: #4b2e1e;
  --screen-bg-clr: #e8c4a2;
}
.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-clr);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0px 0px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  /* box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2); */
}

/* Individual navigation item */
.nav-item {
  position: relative;
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s ease;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding: 0px;
  color: #ffffff;
}

/* Active navigation item */


/* Wrapper for the icon */
.icon-wrapper {
  position: relative;
  background-color: #90542f;
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

/* Active state for the icon wrapper */
.nav-item.active .icon-wrapper {
  background-color: #ffb380;
  color : #ab9586;
  transform: translateY(-18px);
  transition: transform 0.8s ease;
}

/* Styling for the icon image */
.icon-wrapper img {
  width: 22px;
  height: 22px;
}

/* Text below the icon */
.nav-text {
  font-size: 12px;
  margin-top: 4px;
  animation: fadeIn 0.3s ease;
}

/* Fade-in animation for the text */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Slider indicator for the active tab */
.indicator {
  position: absolute;
  margin: -51px -35px 0 0;
  width: 40px;
  height: 40px;
  background: #b6784f;
  border-radius: 50%;
  border: 10px solid var(--screen-bg-clr);
  transition: 0.8s;
}

.indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -22.5px;
  width: 14px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 1px -10px 0 0 var(--screen-bg-clr);
}

.indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -22.5px;
  width: 14px;
  height: 20px;
  background: transparent;
  border-top-left-radius: 20px;
  box-shadow: -1px -10px 0 0  var(--screen-bg-clr);
}

.tabs {
  position: fixed;
  bottom: 20px; /* Adjust this value to control distance from bottom */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  background-color: #90542f;
  padding: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 10;
  border-radius: 25px;
  width: 90%;
  max-width: 400px;
}

.tab {
  color: white;
  cursor: pointer;
  text-align: center;
  padding: 3px 0px;
  margin: 0px 5px;
  flex: 1;
  font-size: 11px;
}


.tab img {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto 5px auto;
}
.active {
  background-color: var(--bg-clr);
}