/* Styles for balance message */
.error-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #dc3545; /* Red background */
    color: white;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    z-index: 1000; /* Ensure it's above other content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; /* Adjust width as needed */
}

.error-message p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
}

.error-message button {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: white;
    color: #dc3545; /* Red color */
    border: 1px solid #dc3545; /* Red border */
    border-radius: 5px;
    cursor: pointer;
}

.error-message button:hover {
    background-color: #da7a83; /* Red background on hover */
    color: white;
}