.airdrop {
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1, h2 {
    text-align: center;
    color: #ffffff;
  }
  
  .roadmap-container {
    flex-grow: 1;
  }
  
  .roadmap {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;
  }
  
  /* This creates the vertical line */
  .roadmap::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    width: 2px;
    background-color: rgba(210, 105, 30, 0.3);
  }
  
  .roadmap li {
    margin: 20px 0;
    padding-left: 30px;
    position: relative;
    color: rgba(139, 69, 19, var(--opacity));
  }
  
  .roadmap li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 12px;
    height: 12px;
    background-color: rgba(210, 105, 30, var(--circle-opacity));
    border-radius: 50%;
    transform: translateY(-50%);
    z-index: 1;  /* This ensures the dot appears above the line */
  }
  
  .roadmap li.completed::before {
    background-color: #ffffff;
    border : solid 4px rgba(50, 205, 50, var(--circle-opacity));
    margin-left: -4px;
  }
  
  .roadmap li.current {
    font-weight: bold;
    font-size: 24px;
  }
  .roadmap li.current::before {
    background-color: rgba(50, 205, 50, var(--circle-opacity));
    border : solid 4px #ffffff;
    margin-left: -4px;
  }
  .mascot{
    display: flex;
    justify-content: center;
  }
  /* ... rest of the CSS remains the same ... */